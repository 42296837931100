<template>
  <div
    class="dropdown"
    v-if="options"
  >
    <input
      class="dropdown-input"
      autocomplete="off"
      :name="name"
      @focus="showOptions()"
      @blur="exit()"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
    />

    <div
      class="dropdown-content"
      :class="{
        radar_active: selectedLang &&
          selectedModuleType === 'radar'
      }"
      v-show="optionsShown"
    >
      <div
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        <template v-if="clientid">
          ({{ option.id }}) {{ option.displayName || option.name || option.identifier || `(Id: ${option.id})` || '-'  }}
        </template>
        <template v-else>
          {{ option.displayName || option.name || option.identifier || `(Id: ${option.id})` || '-' }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    language: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    options: {
      type: Array,
      required: true,
      default: [],
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown',
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing',
    },
    selectedLang: {
      required: false,
    },
    selectedModuleType: {
      required: false,
    },
    defaultValue: {
      required: false,
    },
    clientid: {
      required: false,
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
    };
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'gi');
      this.options.forEach((option) => {
        if (
          (this.searchFilter.length < 1
            || (option.displayName
            && option.displayName.match(regOption))
            || (option.name
            && option.name.match(regOption))
            || (option.identifier
            && option.identifier.match(regOption)))
            && filtered.length < this.maxItem
        ) {
          filtered.push(option);
        }
      });
      return filtered;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
        if (this.clientid) {
          this.searchFilter = `(${option.id}) ${option.displayName || option.name || option.identifier || '-'}`;
        } else {
          this.searchFilter = this.selected.displayName
            || this.selected.name
            || this.selected.identifier
            || `(Id: ${this.selected.id})`
            || '-';
        }
      this.$emit('selected', this.selected);
      this.$emit('index', undefined);
    },
    showOptions() {
      if (!this.disabled) {
        this.temporaryFilter = this.searchFilter;
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      setTimeout(() => {
        this.optionsShown = false;
        this.searchFilter = !this.searchFilter
          ? this.temporaryFilter
          : this.searchFilter;
      }, 100);
    },
    emptyFilter() {
      this.searchFilter = '';
    },
    setDefault(defaultValue) {
      this.searchFilter = defaultValue;
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
    },
    defaultValue: {
      handler(newValue, oldValue) {
        if (typeof newValue === 'string') {
          this.setDefault(this.defaultValue);
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.defaultValue) {
        this.setDefault(this.defaultValue);
      }
    }, 400);
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;
  margin: unset;
  margin-right: 30px;
  width: 240px;
  height: 43px;

  &::after {
    content: "";
    border: solid #32325D;
    border-width: 0 3px 3px 0;
    display: block;
    margin-left: 210px;
    margin-top: -45px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    opacity: 40%;
  }

  .dropdown-input {
    margin-bottom: 1rem;
    padding: 10px 12px;
    width: 100%;
    min-width: 200px;
    height: calc(1.5em + 1.25rem + 2px);
    background: #FFFFFF;
    cursor: pointer;
    border: 1px solid #CAD1D7;
    border-radius: 34px;
    color: #333333;
    display: block;
    font-size: .875rem;
    padding: 6px;

    &:hover {
      background: #F8F8FA;
    }

    &::placeholder {
      color: #32325D;
      opacity: 0.8;
    }
  }

  .dropdown-content {
    position: absolute;
    margin-left: -10px;
    background-color: #FFFFFF;
    width: 240px;
    min-width: 200px;
    max-height: 138px;
    border: 1px solid #E7ECF5;
    border-radius: 5px;
    box-shadow: 0 -8px 34px 0 rgba(0,0,0,0.05);
    overflow: auto;
    z-index: 1;

    &.radar_active {
      margin-top: -160px;
    }

    .dropdown-item {
      min-width: fit-content;
      color: #000000;
      padding: 0.25rem 5px;
      text-decoration: none;
      display: block;
      cursor: pointer;

      &:hover {
        background-color: #E7ECF5;
      }
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}

.row {
  &:not(:first-child) {
    label .dropdown {
      margin-bottom: 0;

      .dropdown-input {
        margin-top: 0 !important;
      }
    }

    .dropdown {
      margin-bottom: 10px;

      .dropdown-input {
        margin-top: 0.8rem;
      }
    }
  }

  &.kpis .kpi,
  &.modules .module {
    .dropdown .dropdown-content {
      margin-top: -15px;
    }
  }
}
</style>
