import axios from "axios";
import { RE_AUTHENTICATE } from "./constants";
import { router } from "./router";

export const axiosInstance = axios.create({
  timeout: 10000,
  retry: 4,
});

axiosInstance.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (err.response) {
    const { status } = err.response;

    switch (status) {
      case 401:
        console.log("401 detected, redirecting to login");
        router.push("/403");
        RE_AUTHENTICATE(status, err.response.data);
        break;
      case 403:
        router.push("/403");
        RE_AUTHENTICATE(status, err.response.data);
        break;
      case 404:
        router.push("/404");
        RE_AUTHENTICATE(status, err.response.data);
        break;
      default:
        console.log("other error");
        break;
    }
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
    return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, config.retryDelay || 1000);
  });
  if (config.retry > 0) {
    return delayRetryRequest.then(() => axiosInstance(config));
  }
  return Promise.reject(err);
});

/* ------------------AXIOS_NO_AUTH------------------
 this instance is used for request "settings.json",
 it requires no authentication
*/
export const axios_no_auth = axios.create();
axios_no_auth.interceptors.request.use(
  (config) => config,
  (error) => {
    router.push("/Error");
    console.error({
      error,
    });
    return Promise.reject(error);
  }
);

axios_no_auth.interceptors.response.use(
  (config) => config,
  (error) => {
    console.log("error", error);
    switch (true) {
      case error.config && error.response && error.response.status === 400:
        router.push("/400");
        break;
      case error.config && error.response && error.response.status === 401:
        router.push("/403");
        RE_AUTHENTICATE(error.response.status, error.response.data);
        break;
      case error.config && error.response && error.response.status === 403:
        router.push("/403");
        RE_AUTHENTICATE(error.response.status, error.response.data);
        break;
      case error.config && error.response && error.response.status === 404:
        router.push("/404");
        break;
      case error.config && error.response && error.response.status === 409:
        router.push("/409");
        break;
      case error.config && error.response && error.response.status >= 500:
        router.push("/500");
        break;
      default:
        router.push("/Error"); // display a general error message to the user
        break;
    }
    console.error({
      error,
    });
    return Promise.reject(error);
  }
);
